import { FC } from 'react'
import Image from 'next/legacy/image'

import { Box, Text } from '@common/react-lib-consumer-pres'
import { useMediaQuery, useTheme } from '@modules/core-ui'
import { useDirectMailUserNameInfo } from '@src/hooks'

export const DirectMailHeader: FC = () => {
  const { userInfo } = useDirectMailUserNameInfo()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'))
  return !!userInfo ? (
    <Box mt={{ xs: 4, md: 6 }}>
      {isMobileView ? (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.extension.colors.base.deepGreen,
            minHeight: 174,
            borderRadius: 4,
            padding: 4,
          })}
        >
          <Text
            use={['h2', { xs: 'subheadings.24b', md: 'subheadings.24b' }]}
            color="text.white"
            mb={2}
          >
            {`Nice to see you, ${userInfo.firstName}!`}
          </Text>
          <Text
            use={['p', { xs: 'subheadings.18m', md: 'subheadings.18m' }]}
            color="text.white"
          >
            {
              'We’ve already filled in some of your information. Please complete the rest of the form to see how much you can save.'
            }
          </Text>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.extension.colors.base.deepGreen,
            minHeight: 174,
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'row',
          })}
        >
          <Image
            alt="Caribou Banner"
            src="/callout-banner.svg"
            width={200}
            height={174}
          ></Image>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.extension.colors.base.deepGreen,
              minHeight: 174,
              borderRadius: 4,
              padding: 4,
            })}
          >
            <Text
              use={['h2', { xs: 'subheadings.24b', md: 'subheadings.24b' }]}
              color="text.white"
              mb={2}
            >
              {`Nice to see you, ${userInfo.firstName}!`}
            </Text>
            <Text
              use={['p', { xs: 'subheadings.18m', md: 'subheadings.18m' }]}
              color="text.white"
            >
              {
                'We’ve already filled in some of your information. Please complete the rest of the form to see how much you can save.'
              }
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  ) : null
}
