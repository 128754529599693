import React, { FunctionComponent } from 'react'

import {
  NewFooter as Footer,
  NewHeader as Header,
  NewPersonalAndCoborrowerForm as PersonalAndCoborrowerForm,
  NewThemeProvider,
} from '@common/react-lib-consumer-pres'
import { DirectMailHeader } from '@modules/direct-mail'

import {
  BaseTemplateNew,
  usePersonalInformationFormModule,
  withProviders,
} from '../src'

export const PersonalInfo: FunctionComponent = () => {
  const {
    module: { SubmitError, ...module },
  } = usePersonalInformationFormModule()

  return (
    <BaseTemplateNew header={<Header />} footer={<Footer />}>
      {SubmitError && <SubmitError sx={{ mb: { xs: 4, sm: 6 } }} />}
      <PersonalAndCoborrowerForm module={module} Banner={DirectMailHeader} />
    </BaseTemplateNew>
  )
}

export default withProviders(NewThemeProvider)(PersonalInfo)
